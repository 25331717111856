'use client';

import * as Sentry from '@sentry/nextjs';
import { usePathname } from 'app/navigation';
import type { AbstractIntlMessages } from 'next-intl';
import { type ReactNode, useEffect } from 'react';

import { NuqsAdapter } from 'nuqs/adapters/next/app';
import AuthProvider from './AuthContext';
import { I18NProvider } from './I18NProvider';
import { ModalProvider } from './ModalContext';
import { PostHogProvider } from './PostHogContext';
import { SWRContext } from './SWRContext';

export function HubSpotNavigationEventsTracker() {
  const pathname = usePathname();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      try {
        if (window.HubSpotConversations?.widget) {
          window.HubSpotConversations.widget.refresh();
        }
        window._hsq = window._hsq || [];
        const _hsq = window._hsq;
        _hsq.push(['trackPageView']);
        _hsq.push(['setPath', pathname]);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }, [pathname]);

  return null;
}

export default function Providers({
  children,
  messages,
  locale,
}: {
  children: ReactNode;
  messages: AbstractIntlMessages;
  locale: string;
}) {
  return (
    <Sentry.ErrorBoundary>
      <AuthProvider>
        <NuqsAdapter>
          <I18NProvider messages={messages} locale={locale}>
            <PostHogProvider>
              <SWRContext>
                <ModalProvider>{children}</ModalProvider>
              </SWRContext>
            </PostHogProvider>
          </I18NProvider>
        </NuqsAdapter>
      </AuthProvider>
    </Sentry.ErrorBoundary>
  );
}
